body {
  .kabanStyle {
    margin-top: 40px;
    .titleKaban {
      display: flex;
      justify-content: space-between;
      padding: 0px 8px 0px 12px;
      .sidebarLabel {
        font-weight: 600;
        font-size: 12px;
        text-transform: uppercase;
        color: #474D66;
      }
      
      .plusIconBtn {
        height: 20px;
        min-width: 20px;
      }
    }
    .bodyBoard,
    .bodyShare {
      margin-bottom: 20px;
      margin-top: 5px;
      .element {
        padding: 0px 8px 0px 12px;
        cursor: pointer;
        line-height: 28px;
        border-radius: 4px;
        &.active {
          background: #EBF0FF;
        }
        &:hover {
          .content {
            color: #3366FF;
          }
        }
        .content {
          color: #101840;
          font-weight: 500;
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
  .toolTipLabel {
    display: flex;
    flex-direction: row;
    align-items: center;
  
    background: #474D66;
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;
  
    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }
}
