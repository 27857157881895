body {


  .documentPreview {
    padding: 8px;
    padding-left: 1rem;
    cursor: move;
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.16);
    border-radius: 4px;
    background-color: #fff;
    min-width: 12rem;

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 16px;
      color: #474D66;
    }
  }
}
