body {
  .control {
    button {
      &[data-active],
      &:hover,
      &:active,
      &:focus {
        background-color: #F3F6FF !important;
        box-shadow: none !important;
        color: #3366FF !important;
      }
    }
  }
}
