@font-face {
  font-family: 'SF Pro Text Semibold';
  src: url(../../assets/fonts/SFProText-Semibold.ttf);
}

.title{
  font-size: 20px;
  font-weight: 600 !important;
  color: #101840;
  font-family: "SF Pro Text Semibold" !important;
}

.newTaskBtn {
  line-height: inherit;
  height: 32px;
  border-radius: 4px;
}

.headlineBtn {
  line-height: inherit;
  height: 32px;
  border-radius: 4px;
}

.inputProjectTitle {
  padding-top: 8px;
  padding-bottom: 8px;
  input{
    border: none;
    font-family: "SF Pro Text Semibold" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #101840;
  }
}

.moreButton{
  padding: 4px 0px;
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #D8DAE5;
  margin-left: 16px;
  border-radius: 3px;
  cursor: pointer;
}

.moreButton:hover{
  border-color: #8f95b2;
  background: #fafbff;
}

.trashButton{
  padding: 8px 16px;
  background-color: #D14343;
  width: 126px;
  height: 32px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.taskDropOver{
  height: 32px;
  width: 100%;
  background-color: #EBF0FF;
  border-radius: 4px;
}
