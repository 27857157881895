body {
  .searchBox {
    display: flex;
    // height: 40px;
    padding: 3px 16px;
    padding-right: 8px;

    // div:first-child {
    //   svg {
    //     fill: #DADADA;
    //   }
    // }

    .searchInput {
      border: none;
      width: 240px;

      &:focus {
        outline: none !important;
        border-color: inherit;
        -webkit-box-shadow: none;
        box-shadow: none;
      }
    }

    .sortIcon {
      margin-left: auto;
      color: #8F95B2;

      &:focus {
        outline: none !important;
        box-shadow: none;
      }
    }
  }
}
