
// custom select
.selectPalette {
  width:  100%;
  font-family: 'SF Pro Text';
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  // custom input
  .css-1uccc91-singleValue {
    padding-left: 0.375rem;
    .input-select {
      &__icon {
        display: none;
      }
    }
  }
  // custom option
  .css-4ljt47-MenuList {
    padding-bottom: unset;
  }
  .css-1n7v3ny-option {
    background-color: #F4F6FA;
  }
  .css-1n7v3ny-option, .css-yt9ioa-option {
    border-bottom: 0.063rem solid #E6E8F0;
  }
  .input-select {
    &__icon {
      display: inline-block;
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.5rem;
      /* position: relative; */
      position: relative;
      top: 1.5px;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
}
