body {
    .formPopoverNocation {
        width: 368px;
        padding: 24px;
        .formHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;
            .title {
                color: #101840;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
            }
            .closeIcon {
                display: flex;
                justify-content: flex-end;
                .icon {
                  cursor: pointer;
                }
            }
        }
        .formBody {
            .item {
                display: flex;
                align-items: center;
                margin-bottom: 16px;
                .icon {
                    cursor: pointer;
                    width: 25px;
                    height: 25px;
                }
            }
        }
    }
}