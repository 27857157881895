@import '../../assets/styles/utils.scss';

html,
body,
#root {
  height: 100%;
}

body {
  background-color: #f4f6fa;
  .app-main-container{
    background-color: #f4f6fa;
  }
  margin: 0;
  font-family: 'SF UI Text', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  .auth-page {
    background-color: #f4f6fa;
  }

  .mt-45 {
    margin-top: 32px;
  }

  .text-decoration-none {
    text-decoration: none;
  }

  .d-mobile-none {
    @include mobile {
      display: none;
    }
  }

  // Custom evergreen ui
  div[role="dialog"] {
    min-width: unset;
    z-index: 20;
  }

  // make sure z-index of tooltip always greater than dialog
  [id^=evergreen-tooltip]{
    z-index: 21 !important;
  }

  div[role="listbox"] {
    min-width: 138px;
    div[role="option"] {
      padding: 8px 0;
      padding-left: 1rem;
    }
  }

  [zwfKeyboardState=hidden] .keyboard{
    display: none;
  }

  .zwfSidebarContainer{
    box-shadow: 0 4px 8px rgba(16, 24, 64, 0.08);
    border-right: none !important;
    z-index: 1;
  }

  .tooltip{
    font-size: 12px;
  }

  .__PrivateStripeElement {
    top: 6px;
  }
}

hr {
  border: none !important;
  height: 1px !important;
  color: #D8DAE5;
  background-color: #D8DAE5;
}

body.dragging {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab !important;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

@media only screen and (max-width: 768px) {
  .zwfSidebarContainer{
    display: none;
  }

  .zwfFormatContainer{
    display: none;
  }

  .zwfToolbar{
    display: none;
  }

  .zmmToolbar {
    display: none !important;
  }

  .zwfMenubar{
    display: none !important;
  }

  .zwfMenubarViewMobile{
    display: none !important;
  }

  .zwfMenubarMobile{
    display: flex !important;
  }
}
