body {
  .textGroup {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;
    button {
      padding: 8px;
    }
    button[data-active="true"] {
      background-color: #F3F6FF;
      color: #3366FF;
    }
    svg {
      color: #8F95B2;
    }
  }
  .fontSize {
    margin-left: auto;
    div {
      margin-bottom: 0;
    }
  }
  .align,.fontFormat {
    display: flex;
    Button {
      padding: 7px !important;
    }
    Button:nth-child(1):hover,Button:nth-child(1):focus {
      border-right: unset;
      box-shadow: unset;
    }
    Button:nth-child(2):hover,Button:nth-child(2):focus {
      box-shadow: unset;
    }
    Button:nth-child(3):hover,Button:nth-child(3):focus {
      border-left: unset;
      box-shadow: unset;
    }

    Button:nth-child(1) {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-right: unset;
    }
    Button:nth-child(2) {
      border-radius: unset;
    }
    Button:nth-child(3) {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-left: unset;
    }
    .activeAlign,.activeFormat {
      background-color: #F3F6FF;
    }
  }
  .palette {
    margin-right: 1rem;
    width: 24px;
    height: 24px;
    border-radius: 24px;
    cursor: pointer;
    &.active,
    &:hover {
      border: 2px solid #9DB5FF;
    }
  }
}
