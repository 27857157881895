body {
  .content {
    padding-top: 1rem;
    .divider {
      margin-bottom: 0;
      margin-top: 1.5rem;
      border: 1px solid #E6E8F0;
    }
  }
}
