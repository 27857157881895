.taskBoard{
  background-color: #FAFBFF !important;
}

.previewDropPosition{
  width: 224px;
  height: 28px;
  border-radius: 4px;
  background-color: #EBF0FF;
}
.projectTask {
  padding: 0px 0px 0px 0px;
}
