.notificationItem{
  min-height: 32px;
  cursor: pointer;
  border-radius: 4px;
  padding: 4px 8px 4px 16px !important;
}

.notificationItem:hover{
  span{
    color: #3366FF;
  }
}

.selected{
  background-color: #EBF0FF;
  border-radius: 4px;
  cursor: pointer;
}

.dropOver{
  border: 2px solid #D6E0FE;
}
