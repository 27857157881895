body {
  .container:hover {
    cursor: pointer;
  }
  .title {
    color: #101840;
    font-weight: 500;
  }
  .content {
    line-height: 20px;
  }
  .status {
    color: #8F95B2;
    font-size: 10px;
  }
  .divider {
    margin-top: 1rem;
    border: 1px solid #E6E8F0;
  }
  .actionBar {
    color: #8F95B2;
  }
  .moreIcon {
    cursor: pointer;
  }
  .tickIcon {
    margin-left: 1rem;
    cursor: pointer;
  }
}
