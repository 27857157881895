@import "../../../assets/styles/utils.scss";

body {
  .card {
    background-color: #fff;
    padding-bottom: 3rem;
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;

    @include mobile {
      padding-bottom: .2rem;
      box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    }
  }

  .cardHeader {
    padding: 1rem 1.4rem 1rem 2.5rem;
    display: flex;
    justify-content: space-between;
  }

  .cardTitle {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

}
