body {
  .header {
    display: flex;
    align-items: center;
    padding: 0.75rem 1.188rem;
    border-top: 1px solid #E6E8F0;
    border-bottom: 1px solid #E6E8F0;
    position: relative;
    width: 100%;
    background-color: #FFF;
    z-index: 4;
    span:last-child {
      margin-left: 4.813rem;
    }
  }
  .close {
    left: 12px;
  }
  .content {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 1rem 1.5rem 4.5rem 1.5rem;
    > div:first-child {
      padding-top: 0;
    }
    > div:last-child {
      hr {
        display: none;
      }
    }
  }
}
