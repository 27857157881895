.versionPanelRegisterCard{
  border-radius: 0px 8px 8px 0px;
  width: 346px;
  height: 470px;

  @media screen and (max-width: 992px) {
    border-radius: 8px;
    width: unset;
  }
}

.versionPanelAlert{
  width: 330px;
  @media screen and (max-width: 992px) {
    width: unset;
  }
}
