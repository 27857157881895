body {
    .formMember {
        width: 300px;
        padding: 24px;
        .formHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;
            .title {
                color: #101840;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
            .closeIcon {
                display: flex;
                justify-content: flex-end;
                .icon {
                  cursor: pointer;
                }
            }
        }
        .listMember {
            .item {
                display: flex;
                justify-content: space-between;
                margin-bottom: 8px;
                cursor: pointer;
                .itemInfo {
                    display: flex;
                    align-items: center;
                    .titleItem {
                        color: #101840;
                        font-weight: normal;
                        font-size: 14px;
                    }
                }
                .iconCheck {
                    display: flex;
                    align-items: center;
                }
            }
        }
    }
}