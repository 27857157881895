body {
  .modal {
    margin-top: auto;
    margin-bottom: auto;
    padding: 0;
    text-align: center;
    max-height: calc(100% - 12vmin);

    background-color: #F4F6FA;
    > div:first-child {
      padding: 0;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(16, 24, 64, 0.08);
      overflow: hidden;
    }
  }

  .modalSuccess {
    margin-top: auto;
    margin-bottom: auto;
    max-height: calc(100% - 12vmin);
    text-align: left;
    h4 {
      color: #52BD94;
    }
  }

  .upgradePlan {
    background-color: #F4F6FA;
    padding: 3.625rem 0 3.625rem 2rem;
  }

  .upgradeCard {
    background-color: #FFFFFF;
    padding: 1.688rem 2.313rem 2.5rem 2.688rem;
    height: 100%;
  }

  .colPr0 {
    padding-right: 0 !important;
  }

  .colPl0 {
    padding-left: 0 !important;
  }

  .close {
    -webkit-box-direction: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    border-radius: 4px;
    box-sizing: border-box;
    position: relative;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    flex-wrap: nowrap;
    justify-content: center;
    -webkit-box-pack: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    outline: none;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    transition: box-shadow 80ms ease-in-out;
    height: 32px;
    min-width: 32px;
    font-size: 12px;
    font-weight: 400;
    color: #8F95B2;
    line-height: 16px;
    padding-left: 0px;
    padding-right: 0px;
    flex: none;
    letter-spacing: 0;
    width: 32px;
    background-color: transparent;
    &:not([disabled]) {
      &:hover {
        background-color: #F4F5F9;
        border: none;
      }
      &:active {
        background-color: #edeff5;
      }
      &:focus {
        box-shadow: none;
        //box-shadow: 0 0 0 2px #d6e0ff;
      }
      &:not([disabled])[aria-expanded="true"] {
        background-color: #edeff5;
      }
      &:not([disabled])[data-active] {
        background-color: #edeff5;
      }
    }
    &[data-simulate-notdisabledhover] {
      background-color: #F4F5F9;
    }
    &[data-simulate-notdisabledfocus] {
      box-shadow: 0 0 0 2px #d6e0ff;
    }
  }

  .tick {
    color: #D8DAE5 !important;
    display: block;
    margin-left: auto;
    width: 16px;
    height: 16px;
  }

  /* Feature */
  .feature {
    margin-top: 2rem;
  }

  .featureTwo {
    margin-top: 1.5rem;
  }

  .featureTwo li {
    margin-bottom: 1rem;
  }

  .featureTwo ul > li:last-child {
    margin-bottom: 0;
  }

  .featureIcon {
    font-size: 10px;
    margin-right: 11px;
    vertical-align: -0.3em;
  }

  /* Plan */
  .plan {
    text-align: left;
    padding: 1rem 1rem 3rem 1.5rem;
    background: #FFFFFF;
    border: 1.5px solid #E6E8F0;
    border-radius: 8px;
  }

  .plan:hover {
    border: 1.5px solid #52BD94;
    cursor: pointer;
  }

  .planSelected {
    border: 1.5px solid #52BD94;
    .tick {
      color: #52BD94 !important;
    }
  }

  .planType {
    display: flex;
    margin-top: 1.5rem;
    justify-content: space-between;
    align-items: center;
  }

  .planPlus {
    height: 100%;
    width: 232px;
    h2, svg {
      color: #696F8C;
    }
  }

  .planPro {
    height: 100%;
    width: 286px;
    padding-left: 2rem;
    h2, svg {
      color: #3366FF;
    }
  }

  .planTeam {
    height: 100%;
    width: 232px;
    h2, svg {
      color: #FFB020;
    }
  }

  .planPrice {
    display: flex;
    vertical-align: middle;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .planFeatureHighlight {
    font-weight: 600;
  }

  .planPriceHighlight {
    margin-top: 1rem;
    margin-bottom: 0.25rem;
    font-weight: 600;
    font-size: 24px;
    margin-right: 0.5rem;
    color: #101840;
  }

  .planSeat {
    display: flex;
    margin-top: 1.9rem;
    justify-content: space-between;
    align-items: center;
  }


  .planSeatLabel {
    flex: 2.33
  }

  .secureIcon {
    margin-right: 0.574rem;
    color: #8F95B2;
    width: 12px;
    height: 12px
  }

  /* Plan */
  //.plan {
  //  margin-top: 2.25rem;
  //}
  //
  //.planSaveBadge {
  //  background: #DCF2EA;
  //  width: 71px;
  //  height: 16px;
  //  border-radius: 4px;
  //  padding: 0 6px;
  //  text-transform: uppercase;
  //  font-size: 11px;
  //  line-height: 16px;
  //  color: #317159;
  //  position: absolute;
  //  top: -11px;
  //  text-align: center;
  //  font-weight: 600;
  //  left: 6px;
  //}
  //
  //.planActive,
  //.planActive:hover {
  //  background-color: #F5FBF8 !important;
  //  color: #429777 !important;
  //  border: 1px solid #52BD94 !important;
  //}
  //
  //.planActive svg[data-icon="tick-circle"] {
  //  color: #52BD94 !important;
  //}
  //
  //.planButton {
  //  text-align: left;
  //  font-size: 12px;
  //  border-radius: 8px;
  //  width: 144px;
  //}
  //
  //.planButton:focus {
  //  box-shadow: none !important;
  //}
  //
  //.planButton:first-child {
  //  margin-right: 0.5rem;
  //}
  //
  //.planButton div:first-child {
  //  margin-right: 8px;
  //}
  //
  //.planButton svg[data-icon="tick-circle"] {
  //  color: #E6E8F0;
  //  width: 16px;
  //  height: 16px;
  //}
  //
  //.planText {
  //  display: flex;
  //  flex-direction: column;
  //}
  //
  //.planText div:first-child {
  //  margin-bottom: -2px;
  //}
  //
  //.planText div:last-child {
  //  margin-top: -2px;
  //  font-weight: 600;
  //}

  .summaryContainer {
    margin-top: 3rem;
    text-align: left;
  }

  .summaryTitle {
    font-weight: 600;
    font-size: 12px;
    text-transform: uppercase;
    color: #101840;
  }

  .summaryList {
    margin-top: 1rem;
  }

  .summaryListItem {
    display: flex;
    justify-content: space-between;
  }

  .summaryListItem span {
    font-size: 14px;
  }

  .summaryPrice {
    color: #101840;
    font-size: 16px !important;
    font-weight: 600;
  }

  .summaryTotal span {
    font-size: 12px
  }

  .form {
    margin-top: 1rem;
  }
}
