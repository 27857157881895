@import "../../../assets/styles/utils.scss";

body {
  .table {
    color: #696F8C;
  }

  .tableHead,
  .tableRow {
    padding-left: 2.5rem;
    height: 48px;
  }

  .tableHead {
    @include mobile {
      display: none;
    }
  }

  .tableRow:first-child {
    @include mobile {
      border-top: 1px solid #edeff5;
    }
  }

  .tableRow:last-child {
    @include mobile {
      border-bottom: none;
    }
  }

  .tableHeaderLastCell {
    padding-right: 1rem;
  }

  .tableHead > div:first-child,
  .tableRow > div:first-child {
    flex: 3
  }

  .tableHead > div:nth-child(2),
  .tableRow > div:nth-child(2) {
    flex: 2
  }

  .tableHeaderLastCell,
  .tableRow > div:last-child {
    flex: 2
  }


  .tableRow:hover > div:last-child {
    visibility: visible !important;
  }

  .tableRow > div:last-child {
    visibility: hidden;
  }

  .tableFirstCell > span {
    color: #101840;
  }

  .tableFirstCell {
    &:hover span {
      cursor: pointer;
      color: #3366FF;
    }
  }

  .tableCell > span {
    color: #696F8C;
  }
}
