body {
  .versionAlert {
    margin-left: auto;
    margin-right: auto;
  }

  .versionAlertText {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #2952cc;
  }
}
