body {
  .alignGroup {
    margin-top: 1rem;
    button {
      padding: 10px;
      border: none;
      border-radius: 0;
      &[data-active] {
        background-color: #F4F6FA !important;
        border-radius: 4px !important;
      }
      &:hover {
        border: none !important;
        background-color: #F4F6FA !important;
        border-radius: 4px !important;
      }
      &:active {
        background-color: #F4F6FA !important;
        border-radius: 4px !important;
      }
      &:focus {
        box-shadow: none !important;
      }
    }
  }
}
