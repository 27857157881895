.heading {
    Button {
      padding: 0 1rem !important;
    }
    Button:nth-child(1):hover,Button:nth-child(1):focus {
      border-right: unset;
      box-shadow: unset;
    }
    Button:nth-child(2):hover,Button:nth-child(2):focus {
      box-shadow: unset;
    }
    Button:nth-child(3):hover,Button:nth-child(3):focus {
      border-left: unset;
      box-shadow: unset;
    }

    Button:nth-child(1) {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
      border-right: unset;
    }
    Button:nth-child(2) {
      border-radius: unset;
    }
    Button:nth-child(3) {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
      border-left: unset;
    }
    .activeHeading {
      background-color: #F3F6FF;
    }
}
