body {
  .formDuaDate {
    // width: 300px;
    padding: 24px;
    .formHeader {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 24px;
        .title {
            color: #101840;
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
        }
        .closeIcon {
            display: flex;
            justify-content: flex-end;
            .icon {
              cursor: pointer;
            }
        }
    }
    .bodyDuaDate {
      .formDateTime {
        display: flex;
        margin-bottom: 16px;
        .date,
        .time {
          .text {
            color: #474D66;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 8px;
          }
          width: 144px;
          input {
            width: 100%;
            font-size: 14px;
          }
        }
        .date {
          padding-right: 5px;
        }
        .time {
          padding-left: 5px;
        }
      }
    }
    .footerDuaDate {
      margin-top: 8px;
      display: flex;
      justify-content: space-between;
      .btnRemove {
        background: #D14343;
        border-color: #D14343;
        color: #fff;
      }
      .btnSave {
        background: #52BD94;
        border-color: #52BD94;
        color: #fff;
      }
    }
  }
}