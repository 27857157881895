.sectionTitle{
  font-size: 12px;
  color: #474D66;
  font-weight: 600;
}

.addProjectBtn{
  width: 20px;
  height: 20px;
  border-radius: 3px;
  border: 1px solid #D8DAE5;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 8px;
}

.previewDropPosition{
  width: 224px;
  height: 28px;
  border-radius: 4px;
  background-color: #EBF0FF;
}

.inputProjectName {
  width: 190px;
  border: none;
  background-color: unset;
  color: #101840;
}
