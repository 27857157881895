body {
  @media only screen and (max-width: 575px) {
    .tableHeaderContainer {
      display: none !important;
    }
  }

  .containerCard {
    background-color: #fff;
    padding: 1.5rem 0 2rem;
  }

  .newDocumentBtn {
    padding: 8px 16px;
    line-height: inherit;
    width: 142px;
    height: 32px;
    border-radius: 4px;
  }

  .menuSelectDocumentItem {
    padding-left: 0;
    padding-right: 66px;
  }

  .tableCell {
    font-family: SF Pro Text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
  }

  .tableHeader {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-transform: uppercase;
  }

  .loadMore {
    width: 100%;
    height: 1px;
    background: transparent;
    border: none;
  }

  .loadMore:hover {
    background: transparent !important;
    border: none !important;
  }

  .actionBtn {
    padding: auto 16px;
    border-radius: 4px;
    width: 65px;
    height: 24px;
    margin-right: 8px;
    color: #fff;
    line-height: inherit;
  }

  .shareBtn {
    background-color: #3366FF;
    border-color: #3366FF;
    line-height: inherit;

    &:hover,
    &:focus {
      background-color: #1F3D99 !important;
      border-color: #1F3D99 !important;
    }
  }

  .moreBtn {
    background-color: #8F95B2 !important;
    border-color: #8F95B2 !important;

    &:hover,
    &:focus{
      background-color: #696F8C !important;
      border-color: #696F8C !important;
    }
  }

  .dragPreview {
    height: 32px;
    padding: 0.5rem 2.5rem;
    margin-left: -2.5rem;
    cursor: move; /* fallback if grab cursor is unsupported */
    cursor: grab;
  }

  .dragPreview:active {
    cursor: grabbing;
  }

  .menuItem {
    padding-left: 0;
  }

  .typeColumn{
    max-width: 120px !important;
  }

  .dateCreated{
    margin-right: 20px;
  }
}
