body {
  .commentBarWidget {
    &:focus {
      box-shadow: none !important;
    }
  }
  .commentBarVisible {
    background: #F3F6FF;
    color: #3366FF;
  }
  .commentBarViewer {
    line-height: 32px;
    height: 32px;
    margin-right: 16px;
  }
  .titleWidget {
    height: 24px;
    width: auto;
    position: relative;
    margin-left: 16px;
    margin-right: 16px;
    .titleLabel {
      font-variant-ligatures: no-contextual;
      margin: 0;
      overflow: hidden;
      padding: 2px 8px;
      pointer-events: none;
      position: absolute;
      text-overflow: ellipsis;
      top: 0;
      white-space: pre;
      z-index: 1;
      &:focus-visible {
        outline: -webkit-focus-ring-color auto 1px;
      }
      .titleLabelInner {
        display: inline;
        margin: 0;
      }
    }
    .titleInput {
      max-width: 356.3px;
      input {
        max-width: 336.3px;
        border: 1px solid transparent;
        border-radius: 2px;
        color: #fff;
        font-size: 14px;
        font-variant-ligatures: no-contextual;
        min-width: 1px;
        padding: 2px 7px;
        visibility: visible;
        width: auto;
        font-family: "SF UI Text", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
        &:hover {
          border: 1px solid #D8DAE5;
          border-radius: 4px;
        }
        &:focus {
          border: 2px solid #1a73e8!important;
          margin: -1px;
          box-shadow: none;
          border-radius: 4px!important;
          -webkit-appearance: none;
          -moz-appearance: none;
          color: #000;
          outline: none;
        }
      }
    }
  }
}
