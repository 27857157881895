body {
  .noteToolbar {
    position: sticky;
    z-index: 10;
    top: 40px;
    width: 100%;

    background: #ffffff;
    // box-shadow: -8px 8px 13px #ededed, 8px -8px 13px #ffffff;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    row-gap: 15px;
    border-top: 1px solid #d8dae5;
    border-bottom: 1px solid #d8dae5;
    height: 40px;
    padding: 0 5px;
    box-sizing: border-box;

    select {
      height: 30px;
      border: none;
      width: 6.9rem;
    }

    &:global(.readOnly) {
      border-bottom: none;
      height: 1px;
    }

    .shortcut {
      margin-left: auto;
      border: 1px solid #d8dae5;
    }
  }

  :global(.slate-ToolbarButton) {
    display: unset;
    width: unset;
    height: unset;
  }
}
