.mxPopupMenu {
  .ub-pt_8px {
    padding-top: unset;
    padding-bottom: unset;
  }
}

div[id^="evergreen-tooltip"][data-state="entered"][style^="left: 6px;"] {
  visibility: hidden !important;
}

.menuPopupItem,.menuPopupItemTrash {
  border-left: 2px solid transparent;
  padding-left: unset;
}
.menuPopupItem:hover {
  background-color: #F3F6FF;
  border-left: 2px solid #3366FF;
  .menuIcon {
    fill: #3366FF;
  }
  span {
    color: #3366FF;
  }
}
// delete
.menuPopupItemTrash:hover {
  background-color: #F3F6FF;
  border-left: 2px solid #D14343;
}

.table-btn{
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.table-icon{
  width: 16px;
  height: 16px;
  color: #8F95B2;
}

.tooltip{
  z-index: 99999;
}
