body {
  .modal {
    background: #FFFFFF;
    .modalContent {
      .inputInvite {
        width: 100%;
        padding-right: 0;
      }
      .btnInvite {
        width: 100%;
      }
      .hr {
        border: 1px solid #E6E8F0;
        background: #E6E8F0;
        width: 100%;
        align-self: center;
        margin-block-end: 0;
        margin-block-start: 0;
        box-sizing: border-box;
        margin-top: 32px;
        margin-bottom: 32px;
      }
      .formPermiss {
        .title {
          font-size: 16px;
          font-weight: 600;
        }
        .titlePer {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
