@font-face {
  font-family: 'SF Pro Text Semibold';
  src: url(../../assets/fonts/SFProText-Semibold.ttf);
}

.keyResultInfo{
  height: 40px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
  padding-left: 16px;
  padding-right: 16px;
}

.dropResultOver{
  height: 40px;
  background-color: #EBF0FF;
  width: 100%;
  border-radius: 4px;
  //margin-top: 4px;
  //margin-bottom: 4px;
}

.keyResultSelected{
  background-color: #EBF0FF;
}

.inputTask{
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
  background-color: white;
}

.inputText{
  ::placeholder{
    color: #C1C4D6;
  }
}

.descriptionArea{
  color: #101840;
  font-size: 14px;
  padding: 0px;
  resize: none;
  border: none !important;
  width: 100%;
  align-self: center;
  min-height: 18px;
  font-family: "SF Pro Text" !important;
  line-height: 20px;
}

.descriptionArea:focus{
  outline: none;
}

.deleteBtn:hover{
  background-color: #fafbff;
  border: 1px solid #8f95b2;
}


.sliderWrap{
  width: 112px;
  height: 24px;
  padding-left: 16px;
  display: grid;
}

.slider{
  margin: 0px !important;
  align-self: center;
}

.progress{
  //width: 67px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #D8DAE5;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
  min-width: max-content;
}

.inputProgress{
  width: 20px;
  border: none;
  text-align: right;
  color: #8F95B2;
  font-size: 12px;
}

.inputProgress:focus{
  outline: none;
}

/* Chrome, Safari, Edge, Opera */
.inputProgress::-webkit-outer-spin-button,
.inputProgress::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.inputProgress[type=number] {
  -moz-appearance: textfield;
}

.percent{
  color: #8F95B2 !important;
  font-size: 12px !important;
}

.noSelectAble{
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
}
