.selectStatus {
  width:  100%;
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 0.75rem;
  line-height: 1rem;
  &:hover {
    cursor: pointer;
  }
  // custom input
  .css-1uccc91-singleValue {
    padding-left: 0.375rem;
    .input-select {
      &__icon {
        display: none;
      }
    }
  }
  // custom option
  .css-4ljt47-MenuList {
    padding-bottom: unset;
  }
  .css-1n7v3ny-option {
    background-color: #F4F6FA;
  }
  .css-1n7v3ny-option, .css-yt9ioa-option {
    border-bottom: 0.063rem solid #E6E8F0;
  }
  .css-1uccc91-singleValue, .input-select, .input-select__single-value {
    width: 100%;
  }
  .css-1yeq0m7-ValueContainer {
    .input-select {
      &__label {
        color: #8F95B2;
      }
      &__counter {
        display: none;
      }
    }
  }
  .input-select {
    &__single-value {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
    &__counter {
      background: #D6E0FF;
      border-radius: 4px;
      padding: 0 6px;
      color: #2952CC;
      font-weight: 600;
      font-size: 11px;
      line-height: 16px;
      width: 12px;
      height: 16px;
      text-align: center;
    }
  }
  .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
    padding-top: 6px;
    padding-bottom: 6px;
    height: 20px;
    width: 20px;
    margin-bottom: 5px;
    background: #f4f6fa;
    border-radius: 4px;
    margin-right: 6px;
    svg {
      position: absolute;
      right: 12px;
      bottom: 10px;
      font-size: 11px;
      color: #8F95B2;
    }
  }
}
