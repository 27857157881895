@font-face {
  font-family: 'SF Pro Text';
  src: url(../../../../assets/fonts/FontsFree-Net-SFProText-Regular.ttf);
}

.sidebar-box {
  height: 100%;
  padding-bottom: 109px;
  padding-left: 0 !important;
  padding-right: unset !important;
}
.titleBox {
  text-align: center;
  position: relative;
  padding: 0.625rem 0 0.625rem;
  border-bottom: 0.063rem solid #D8DAE5;

  label {
    font-family:'SF Pro Text';
    font-style: normal;
    font-weight: normal;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }
  .crossSidebar {
    position: absolute;
    top: 0;
    right: 0.938rem;
    > svg {
      color: #8F95B2;
    }
    > svg:hover {
      fill: #6a6c72;
    }
  }
  .crossSidebar:hover {
    cursor: pointer;
  }
}
.filterBox {
  border-bottom: 0.063rem solid #D8DAE5;
  .filterSidebar {
    display: flex;
    margin-top: 1rem;
    margin-bottom: 1rem;
    .buttonSelect {
      margin-right: 0;
      margin-left: 0.5rem;
    }
  }
}
.resultSearch {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  overflow: auto;
  max-height: 100%;
  padding: 16px;
  justify-content: space-between;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */
  a.geItem {
    display: flex;
    justify-content: center;
    align-content: center;
    box-sizing: border-box;
    text-decoration: none;
    align-items: center;
    margin-bottom: 8px;
    border: 0.063rem solid #eeeeee;
    padding: 2.125rem 0 !important;
    border-radius: 0.25rem;
    color: #000000;
  }
  a.geItem:hover {
    background-color: #F4F6FA;
  }
}

.resultSearch::-webkit-scrollbar {
  width: 6px;
}

/* Track */
.resultSearch::-webkit-scrollbar-track {
  background: #ffffffff;
}

/* Handle */
.resultSearch::-webkit-scrollbar-thumb {
  background: #C1C4D6;
  border-radius: 0.5rem;
}

/* Handle on hover */
.resultSearch::-webkit-scrollbar-thumb:hover {
  background: #555;
}
