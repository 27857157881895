body {
  .modal {
    width: calc(34rem + 2px);
    margin-top: auto;
    margin-bottom: auto;

    div[role="combobox"] {
      margin-left: 8px;
      display: inline-block;
      width: auto !important;

      div[role="group"] {
        width: 138px;
      }

      & button {
        height: 32px;
      }
    }

    .content {
      margin-top: -16px;
      padding-bottom: 32px;
    }

    & h4:first-child {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #101840;
    }

    .selectedRole {
      width: 120px;
      margin-left: 8px;
    }

    .inviteBtn {
      width: 65px;
      margin-left: 8px;
      line-height: inherit;
    }

    .copyBtn {
      width: 110px;
      height: 32px;
      background: #FFFFFF;
      border: 1px solid #D8DAE5;
      box-sizing: border-box;
      border-radius: 4px;
      line-height: inherit;
    }

    hr {
      border: 1px solid #e6e8f0;
      margin-top: 32px;
      margin-bottom: 24px;
    }

    .permissionHeading {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #101840;
    }

    .sharedList {
      padding-top: 22px;
    }

    .sharedItem {
      display: flex;
      flex-direction: row;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #474D66;
      align-items: center;
    }

    .sharedItem:not(:last-child) {
      margin-bottom: 8px;
    }

    .shareTarget {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #474D66;
    }

    .shareAction {
      margin-left: auto;
    }
  }
}
