@import '../../assets/styles/utils.scss';

body {
  .noteEditor {
    background: #fff;

    > div[data-slate-editor] {
      padding: 1.75rem 2.5rem 1rem !important;
      font-size: 0.9375rem !important;
      line-height: 1.5rem !important;
      min-height: calc(100vh - 100px) !important;
      z-index: 1;
      // max-height: calc(100vh - 80px) !important;

      span[data-slate-placeholder] {
        min-width: 200px !important;
      }

      a {
        cursor: pointer;
      }

      ul,
      ol {
        padding-inline-start: 2.5rem;
      }

      ul {
        list-style: unset !important;
      }

      body {
        margin: 0;
      }

      p {
        margin: 0;
        // padding: 0;
        line-height: 1.5rem !important;
      }

      pre {
        padding: 10px;
        background-color: #eee;
        white-space: pre-wrap;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        margin: 0;
        color: #000;
      }

      h1 {
        font-size: 2rem;
        margin-block-start: 0.67em;
        margin-block-end: 0.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      h2 {
        font-size: 1.5rem;
        margin-block-start: 0.83em;
        margin-block-end: 0.83em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      h3 {
        font-size: 1.25rem;
        margin-block-start: 1em;
        margin-block-end: 1em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      h4 {
        font-size: 1.125rem;
        margin-block-start: 1.33em;
        margin-block-end: 1.33em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      h5 {
        font-size: 1rem;
        margin-block-start: 1.67em;
        margin-block-end: 1.67em;
        margin-inline-start: 0px;
        margin-inline-end: 0px;
      }

      :not(pre) > code {
        font-family: monospace;
        background-color: #eee;
        padding: 3px;
      }

      img {
        max-width: 100%;
        max-height: 20em;
      }

      blockquote {
        border-left: 2px solid #ddd;
        margin-left: 0;
        margin-right: 0;
        padding: 0 10px;
        font-style: italic;
        color: #000;
      }

      blockquote[dir='rtl'] {
        border-left: none;
        padding-left: 0;
        padding-right: 10px;
        border-right: 2px solid #ddd;
      }

      table {
        border-collapse: collapse;
      }

      td {
        padding: 10px;
        border: 2px solid #ddd;
      }

      input {
        box-sizing: border-box;
        font-size: 0.85em;
        width: 100%;
        padding: 0.5em;
        border: 2px solid #ddd;
        background: #fafafa;
      }

      input:focus {
        outline: 0;
        border-color: blue;
      }

      iframe {
        width: 100%;
        border: 1px solid #eee;
      }
    }
  }

  .noteMenu {
    width: 100%;
    display: flex;
    min-height: 40px;
    position: sticky;
    z-index: 10;
    top: 0;
    background-color: #fff;
    align-items: center;
  }

  .noteTitle {
    input {
      border: none;
      background: #fff;
      padding-left: 0;
      font-size: 1rem;

      &:focus {
        outline: 0;
        border-left: unset;
        box-shadow: unset;
      }
    }
  }

  .actionContainer {
    margin-right: auto;
    display: flex;
    align-items: center;
  }

  .tagContainer {
    flex: 1;
    padding: 0 1rem;
    align-items: center;
  }
}
