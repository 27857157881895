body{
  .mainPage{
    @media only screen and (min-width: 768px) {
      padding: 2.5rem;
    }
    @media only screen and (max-width: 768px) {
      padding-bottom: 2.5rem;
    }
    .sounds{
      grid-template-columns: 258px 258px 258px 258px;
      @media only screen and (max-width: 1400px) {
        grid-template-columns: 258px 258px 258px;
      }
      @media only screen and (max-width: 768px) {
        grid-template-columns: 100%;
      }
      display: grid;
      grid-gap: 24px 16px;
      .sound{
        padding-top: 16px;
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
      }
      .card {
        border-radius: 8px;
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
        @media only screen and (min-width: 1200px) {
          height: 172px;
        }
        @media only screen and (max-width: 1200px) {
          height: calc(172px + 35px);
        }
        cursor: pointer;
        &:hover {
          .image {
            filter: grayscale(0%);
          }
          .text {
            color: #3366FF;
          }
        }
        .image {
          border-top-right-radius: 8px;
          border-top-left-radius: 8px;
          width: 100%;
          @media only screen and (min-width: 1200px) {
            height: 132px;
          }
          @media only screen and (max-width: 1200px) {
            height: calc(132px + 35px);
          }
          filter: grayscale(100%);
        }
        .iconButton {
          border-radius: 50%;
        }
      }
      .cardActive {
        .image {
          filter: grayscale(0%);
        }
        .text {
          color: #3366FF;
        }
      }
    }
    .titleBottom {
      margin-top: 40px;
    }
    .titleBottom,
    .titleBottom2 {
      font-style: normal;
      font-size: 14px;
      line-height: 20px;
      color: #474D66;
      margin-bottom: 20px;
    }
  }
}
