body {
    .formPopover {
        width: 208px;
        padding-bottom: 8px;
        .title {
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            text-transform: uppercase;
            color: #696F8C;
            padding: 16px 16px 0px 16px;
        }
        .actionEdit,
        .actionRemove {
          button {
            width: 100%;
            justify-content: flex-start;
            padding: 20px;
            font-size: 14px;
          }
        }
        .actionEdit{
          button {
            color: #8F95B2;
          }
        }
        .actionRemove {
          button {
            color: #D14343;
          }
        }
    }
    .formChangeBg {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
        grid-gap: 8px;
        padding-bottom: 25px;
        .buttonChangeBg {
            color: #fff;
            path {
                fill: #fff;
            }
            &:hover {
                background: 0%;
            }
        }
    }
    .headerMainBoard {
        position: fixed;
        width: calc(100% - 17.5rem - 40px);
        display: flex;
        justify-content: space-between;
        padding: 0px 8px 0px 8px;
        height: 40px;
        align-items: center;
        color: #fff;
        .actionLeft {
            display: flex;
            align-items: center;
            .title {
                font-weight: 600;
                font-size: 20px;
            }
            .moreIcon {
                margin-left: 16px;
                margin-right: 16px;
            }
            .filterIcon {
                button {
                    color: #fff;
                    font-size: 12px;
                    &:hover {
                        background-color: #fff3;
                    }
                }
            }
        }
        .actionRight {
            display: flex;
            align-items: center;
            .avatars {
                display: flex;
                align-items: center;
                margin-right: 16px;
            }
            .inviteIcon {
                button {
                    color: #3366FF;
                }
            }
        }
    }
}