body {
  .notebookSidebar {
    
    .item {
      display: flex;
      align-items: center;
      padding-left: 1rem;
      padding-right: .5rem;
      height: 2rem;

      cursor: pointer;

      a {
        display: flex;
        align-items: center;
        overflow: hidden;
        flex: 1;
      }

      span {
        font-style: normal;
        font-size: 14px;
        line-height: 24px;
        color: #101840;

        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      &:hover {
        span {
          color: #3366FF;
        }

        .optionIcon {
          visibility: visible;
        }
      }
  
      &:global(.isActive) {
        background-color: #ebf0ff !important;
        border-radius: 4px;
      }

      .optionIcon {
        visibility: hidden;
        min-width: unset;
        margin-left: auto;
        width: 20px;
        height: 20px;
        background: #FFFFFF;
        border: 1px solid #D8DAE5;
        box-sizing: border-box;
        border-radius: 3px;
      }

      .sidebarLabel {
        padding-left: 18px;
      }
    }

    
  }
}
