@import "../../assets/styles/utils.scss";

body {
  .canDrop {
    text-decoration-line: underline !important;
  }

  .asideContainer {
    display: flex;
    flex-direction: column;
    box-shadow: 0px 4px 8px #10184014;
    background-color: #fff;
    height: 100vh;
    overflow-y: auto;
    width: 17.5rem;
    line-height: 1.5;
    color: #696f8c;
    padding: 24px 24px 24px 32px;
  }

  .main {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    flex: 1;
    background-color: #fff;

    @include mobile {
      min-height: unset;
    }
  }

  .mainContent {
    width: 100%;
    height: 100%;
    //max-width: 72.5rem;
    margin: 0 auto;
    box-sizing: border-box;
  }

  .allDocumentHeading {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .pageWrapper {
    display: flex;
    flex-direction: column;
    padding: 4rem 3rem 3rem;
    min-height: 100vh;
    width: 100%;
    margin: 0 auto;
  }

  .sidebarItem {
    padding: 6px 0;
    font-weight: normal;
    color: #8f95b2;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      line-height: 24px;
    }

    &:hover,
    &:global(.isActive) {
      color: #3366FF !important;

      .sidebarLabel {
        color: #3366FF !important;
      }
    }
  }

  .bottomSidebar {
    margin-top: auto;
    bottom: 1rem;
    padding-right: 2rem;
    z-index: 200;
    position: fixed;
    bottom: 24px;
    background-color: #fff;
  }

  .hr {
    border: 1px solid #e6e8f0;
    background: #E6E8F0;
    width: 13.5rem;
    align-self: center;
    margin-block-end: 0;
    margin-block-start: 0;
    box-sizing: border-box;
  }

  .plusIconBtn {
    min-width: unset;
    margin-left: auto;
    width: 20px;
    height: 20px;
    background: #FFFFFF;
    border: 1px solid #D8DAE5;
    box-sizing: border-box;
    border-radius: 3px;
  }

  .upgradeBtn {
    margin-top: 1.5rem;
    width: 144px;
    height: 40px;
    background: #3366FF;
    border-radius: 4px;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #FFFFFF;
    text-align: center;
  }

  .folderContainer {
    margin-top: 38px;
  }

  .sidebarLabel {
    margin-left: 18px;
    font-size: 14px;
    line-height: 24px;
    color: #696F8C;
  }

  .toolTipLabel {
    display: flex;
    flex-direction: row;
    align-items: center;

    background: #474D66;
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }

  .mobileAlert {
    @include mobile {
      min-height: 4.83333333rem;
      h4 {
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        color: #2952CC;
      }

      p {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #696F8C;
      }
    }
  }

  @media only screen and (min-width: 1024px) {
    .container {
      display: flex;
      margin-left: 17.5rem;
    }

    .aside {
      position: fixed;
      left: 0;
    }

    .mobileHeader {
      display: none;
    }
  }

  @media only screen and (max-width: 768px) {
    .aside {
      display: none !important;
    }

    .container {
      padding: 0 24px;
    }

    .mobileLogo {
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
    }

    .mainContent {
      padding: 0;
    }

    .mobileCombobox {
      margin-top: 1.5rem;
      margin-bottom: 1rem;

      input {
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
        color: #8F95B2;
      }
    }

    div[role="listbox"] div[role="option"] {
      padding: 8px 1rem;
    }
  }
}
