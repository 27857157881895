@import "../../assets/styles/utils.scss";

body {

  .mainSection {
    display: flex;
    flex-direction: row;
    justify-content: left;
    // overflow: hidden;
    background-color: #fff;
    height: 100%;
  }

  .listContainer {
    width: 320px;
    min-height: 100vh;
    border-left: 1px solid #E6E8F0;
    border-right: 1px solid #E6E8F0;
    position: fixed;
    box-sizing: border-box;
  }

  .editorContainer {
    flex: 1;
    margin-left: 320px;
  }

  .viewerBody {
    width: 100%;
    overflow-y: scroll;

    @include desktop {
      padding: 4.6% 17% 0.375rem;
    }
  }
}
