.green-hover:hover{
  background: #EEF8F4 !important;
}

.yellow-hover:hover{
  background: #FFEFD2 !important;
}

.blue-hover:hover{
  background: #EBF0FF !important;
}

.border-lighten-gray{
  border: 1px solid #D8DAE5;
}

.on-one-line{
  min-width: max-content;
}

@media only screen and (max-width: 1250px) {
  .shape-buttons{
    display: none;
  }
}
