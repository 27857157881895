body {
  .terms {
    font-size: 12px;
    text-align: center;
  }

  .column{
    padding: 0px !important;
  }

  .columnCenterMd{
    @media screen and (max-width: 992px) {
      display: flex;
      justify-content: center;
    }
  }

  .fixWidthMd{
    width: 346px;
    @media screen and (max-width: 992px) {
      width: unset;
    }
  }

  .versionPanelColumn{
    @media screen and (max-width: 992px) {
      display: none;
    }
  }

  .footer{
    margin-top: 8px !important;
  }

  .leftPanel{
    height: 100%;
  }

  .feature{
    width: 40px;
    height: 40px;
    display: flex;
    border-radius: 4px;
    justify-content: center;
  }

  .spaceBetween {
    margin-right: 2.2rem;
  }

  .spaceMiddle {
    margin-right: 1.938rem;
  }

  .spaceLast {
    margin-right: 1.5rem;
  }

  .featureText{
    color: white;
    align-self: center;
    margin-left: 12px;
    font-size: 14px;
    font-weight: 500;
  }
}
