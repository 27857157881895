.dueDatePicker{
  background: url("Icons/Calendar.svg");
  width: 24px;
  height: 24px;
  border: none;
  float: left;
  .ant-picker-input{
    display: none;
  }
}

.dueDatePicker:hover{
  background: url("Icons/HoverCalendar.svg");
}

.hiddenDatePicker{
  visibility: hidden;
  width: 1px;
  height: 1px;
}
