#mindplot {
  position: absolute;
  top: 0;
  background-color: #FFFFFF;
}
.emoji-picker {
  opacity: 1 !important;
}
#textContainer textarea {
  font-size: 15.5px !important;
  font-family: system-ui !important;
  padding: 0 !important;
}

.popover {
  width: 533px;
  padding: 2rem;
  background: #FFFFFF;
  box-shadow: 0 12px 40px rgba(16, 24, 64, 0.24);
  border-radius: 8px;
  margin-top: 1rem
}

.popover .popover-title {
  color: #474D66;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}

.popover .popover-content {
  font-size: 14px;
  line-height: 20px;
  color: #474D66;
}

.popover .popover-content #textPopoverNote {
  padding: 0 !important;
}
