body {
  .noteMenubar {
    display: flex;
    align-items: center;
    margin-left: auto;
    padding-right: 1rem;
  }

  .toolTipLabel {
    display: flex;
    flex-direction: row;
    align-items: center;

    background: #474D66;
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;

    font-style: normal;
    font-weight: normal;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
  }
}
