:global {
  body {
    .react-datepicker {
      border: 0;
      color: #444444;
      font-size: 14px;
      font-family: "Museo Sans", "Helvetica Neue", Arial, Helvetica, sans-serif;
      font-weight: 400;
      width: 100%;
      padding-bottom: 8px;
    }

    .react-datepicker__month-container {
      width: 100%;
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header {
      color: #444;
      font-weight: 500;
      font-size: 14px;
      line-height: 32px;
      padding-bottom: 4px;
      text-transform: capitalize;
    }

    .react-datepicker__header {
      background: #fff;
      border: 0;
      padding: 0;
    }

    .react-datepicker__day-name,
    .react-datepicker__day,
    .react-datepicker__time-name {
      color: #444;
      display: inline-block;
      width: 40px;
      font-weight: 500;
      line-height: 28px;
      text-align: center;
      margin: 0;
      padding: 0!important;
      border-style: solid;
      border-width: 0 0 1px 1px;
      border-color: rgba(34, 36, 38, 0.15);
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      border-radius: 0;
      background: #216ba5;
      color: #fff;
    }

    .react-datepicker__day-names,
    .react-datepicker__week {
      border-right: 1px solid #c2ccd1;
    }

    .react-datepicker__day-names {
      background: #ebeef0;
      border-top: 1px solid #c2ccd1;
    }

    .react-datepicker__day--outside-month {
      color: transparent;
      background: #f5f6f7;
    }

    .react-datepicker__month {
      margin: 0.4rem 0 0;
    }

    .react-datepicker__navigation--previous,
    .react-datepicker__navigation--next {
      outline: none;
    }

    .react-datepicker__navigation--previous {
      left: 0;
      border-right-color: #999;
    }

    .react-datepicker__navigation--next {
      right: 0;
      border-left-color: #999;
    }

    .react-datepicker__navigation {
      border-width: 7px 9px;
      top: 0;
    }

    .react-datepicker__day {
      outline: none;

      &:hover {
      border-radius: 0;
      }
    }
  }
}