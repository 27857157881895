body {
  .card {
    margin-top: 2rem;
    padding: 2.5rem;
    box-shadow: 0 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;
    background-color: #FFFFFF;
  }
  .cardTeam {
    margin-top: 2rem;
    box-shadow: 0 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;
    background-color: #FFFFFF;
  }

  .buttonDelete{
    padding: auto 16px;
    border-radius: 4px;
    width: 65px;
    height: 24px;
    line-height: inherit;
  }

  .bodyCell{
    font-size: 14px;
    color: #101840;
    font-weight: 400;
    font-family: "SF Pro Text";
  }

  .tableRow:hover > div:last-child {
    visibility: visible !important;
  }

  .tableRow > div:last-child {
    visibility: hidden;
  }

  .portalLink {
    color: #3466FF;
    cursor: pointer;
    text-decoration: underline;
  }
}
