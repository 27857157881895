body {
  .container {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .subtitle {
    margin-top: 0.5rem;
  }

  .deleteAccount {
    color: #D14343;
    &:hover {
      background-color: #edeff5 !important;
    }
  }

  .divider {
    border: 0.031rem solid #D8DAE5;
    margin-top: 1.5rem;
  }

  .card {
    margin-top: 2rem;
    padding: 2.5rem;
    box-shadow: 0 4px 8px rgba(16, 24, 64, 0.08);
    border-radius: 8px;
    background-color: #FFFFFF;
  }

  .form input {
    display: block;
  }
  .formGroup {
    margin-top: 1rem;
    display: block;
  }
  .formGroup input {
    display: inline;
  }

}
