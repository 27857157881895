body {
  .header {
    display: flex;
    align-items: center;
    padding: 0.25rem 1.188rem 0.25rem 0.55rem;
    border-top: 1px solid #E6E8F0;
    border-bottom: 1px solid #E6E8F0;
    position: relative;
    justify-content: space-between;
    width: 100%;
    background-color: #FFF;
    z-index: 4;
    button {
      position: static;
    }
  }
  .close {
    left: 12px;
  }
  .contentContainer {
    position: relative;
    height: 100%;
  }
  .preContent {
    margin: 1rem;
  }
  .divider {
    margin-top: 1rem;
  }
  .content {
    position: relative;
    height: 100%;
    overflow: auto;
    padding: 1rem 1rem 8rem 1rem;
    > div:first-child {
      padding-top: 0;
      margin-top: 0;
    }
  }
  .footer {
    display: flex;
    align-items: center;
    padding: 0rem 2.375rem;
    border-top: 1px solid #E6E8F0;
    border-bottom: 1px solid #E6E8F0;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #FFF;
    z-index: 4;
  }
  .footerText {
    font-size: 12px;
    line-height: 16px;
  }
}
