.left-panel{
  min-width: 280px;
  right: 0px;
  top: 0px;
  position: fixed;
  height: 100%;
  background: white;
}

.main-content{
  margin-right: 280px;
  background: #E6E8F0;;
  height: 100%;
}

.version-sheet{
  overflow-y: clip;
}
