body{
  .shareFolder {
    cursor: pointer;
    margin: 0 0 0 1.1em !important;
  }

  .preIcon{
    //color: #8F95B2;
    margin-right: 10px;
    margin-left: 1px;
    cursor: pointer;
  }

  .beforeFolderItem {
    height: 6px;
    box-sizing: border-box;
  }

  .folderCaret {
    margin-right: 10px;
  }

  .folderParentCaret {
    color: #8F95B2;
  }

  .folderChildCaret {
    color: #D8DAE5;
  }

  .rootFolder{
    padding-top: 8px;
    padding-bottom: 4px;
  }

  .folderName {
    cursor: pointer;
    display: flex;
    align-items: center;

    .folderIcon {
      color: #8f95b2;
    }

    &:hover,
    &:global(.isActive) {
      color: #3366FF !important;

      .folderNameLabel,
      .folderIcon {
        color: #3366FF !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 160px;
      }
    }
  }

  .folderNameLabel {
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    padding-left: 7px;
    color: #696F8C;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
    display: inline-block;
    cursor: pointer;
  }

  .childrenFolders{
    padding-left: 13px;
    margin: 0 0 0 1.1em !important;
  }

  .childFolder{
    padding-top: 11px;
    padding-top: 12px;
  }

  .afterFolderItem {
    height: 1px;
    box-sizing: border-box;
    padding-left: 16px;
    &:global(.canDrop) {
      border-bottom: 1px solid #696F8C;
    }
  }

  .shareMenu {
    position: absolute;
    z-index: 100;
    width: 13rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.16);
    border-radius: 4px;

    .contextItemLabel {
      font-size: 14px;
      line-height: 20px;
      color: #474D66;
    }

    .menuItem {
      padding-left: 0;

      &:global(.trash-item) {
        color: #D14343;

        .contextItemLabel {
          color: #D14343;
        }
      }
    }
  }

}
