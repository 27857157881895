body {
  .container {
    height: 32px;
    background: #FFFFFF;
    border: 1px solid #D8DAE5;
    box-sizing: border-box;
    border-radius: 4px;
    width: 100%;
    padding: 0.3rem 0.75rem;
    cursor: text;
  }
  .label {
    font-size: 12px;
    color: #8F95B2;
  }
  .textarea {
    width: 100%;
  }
  .buttonGroup {
    margin-top: 0.5rem;
    text-align: right;
  }
  .buttonComment {
    margin-left: 0.5rem;
  }
}
