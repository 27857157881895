// $mobile-width: 480px;
$mobile-width: 768px;
$tablet-width: 768px;
$desktop-width: 1024px;

@mixin mobile { @media (max-width: #{$mobile-width - 1px}) {
  @content;
 }
}

@mixin tablet { @media (min-width: #{$tablet-width}) and (max-width: #{$desktop-width - 1px}) {
   @content;
 }
}

@mixin desktop { @media (min-width: #{$desktop-width}) {
  @content;
 }
}

.w-0\.5 {
  width: 0.125rem;
}

.absolute {
  position: absolute;
}
.text-white {
  --tw-text-opacity: 1;
  color: rgba(255,255,255,var(--tw-text-opacity));
}

.text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.rounded-bl-none {
  border-bottom-left-radius: 0;
}

.rounded {
  border-radius: 0.25rem;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.top-0 {
  top: 0px;
}

.pointer-events-none {
  pointer-events: none;
}

.justify-center {
    justify-content: center;
}

.flex {
  display: flex;
}

.relative {
  position: relative;
}
