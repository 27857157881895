body {

  .asideContainer {
    display: flex;
    flex-direction: column;
    // box-shadow: 0px 4px 8px #10184014;
    background-color: #fff;
    height: 100vh;
    overflow-y: auto;
    width: 17.5rem;
    line-height: 1.5;
    color: #696f8c;
    padding: 1.25rem 1rem;
    .header {
      padding: 0px 8px 0px 12px;
    }
  }

  .headerBar {
    padding-left: .5rem;
    padding-right: .5rem;
  }

  .topSidebar {
    padding-top: 3rem;
    background-color: #FAFBFF;
  }

  .mynoteBox {
    display: flex;
    align-items: center;
    padding-right: .5rem !important;

    a {
      flex: 1;

      h2 {
        display: flex;
        align-items: center;
      }
    }
  }

  .taskBoard {
    background-color: #fafbff !important;
  }

  .sectionTitle {
    font-size: 12px;
    color: #474d66;
    font-weight: 600;
  }

  .tagHeading {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    color: #474D66;
    padding-left: .5rem;
  }

  .sidebarItem {
    padding: 4px 8px;
    padding-right: 0;
    font-weight: normal;
    color: #8f95b2;
    cursor: pointer;

    a {
      display: flex;
      align-items: center;
      line-height: 1.5rem;
    }

    &:hover {
      span {
        color: #3366FF;
      }
    }

    &:global(.isActive) {
      background-color: #ebf0ff !important;
      border-radius: 4px;
    }
  }

  .sidebarLabel {
    margin-left: 18px;
    font-size: 14px;
    line-height: 1.5rem;
    color: #101840;
    font-weight: normal;
  }
  
  .tagContainer {
    margin-top: 2rem;
  }

  .tagBody {
    margin-top: 6px;
  }

}
