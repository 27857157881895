body {
  .tagSidebar {
    // max-height: 18.75rem;
    overflow: hidden;
    padding-bottom: 2.5rem;

    &:hover {
      overflow-y: auto;
    }

    .tagItem {
      height: 32px;
      display: flex;
      align-items: center;
      padding: 0 .5rem;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #101840;

      a {
        font-size: 14px;
        line-height: 24px;
        color: #101840;
      }

      &:global(.isActive) {
        background-color: #EBF0FF !important;
        border-radius: 4px;
      }

      &:hover {
        color: #3366FF;
        // .tagIcon {
        //   color: #3366FF;
        // }
        a {
          color: #3366FF;
        }
      }

      .tagIcon {
        margin-right: 1rem;
        color: #C1C4D6;
      }
    }

    
  
    a,
    a:hover,
    a:visited,
    a:active {
      text-decoration: none;
    }
  }  
}
