body {
  .modal {
    margin-top: auto;
    margin-bottom: auto;
    > div:last-child button:last-child {
      background-color: #52BD94;
      border-color: #52BD94;
    }

    > div:last-child button:last-child:global(.primary-btn) {
      background-color: #3366FF;
      border-color: #3366FF;
    }
  }

  .content {
    font-size: 14px;
    color: #474D66;
    font-weight: 400;
  }

  .modal h4 {
    font-style: normal;
  }

  .dangerModal h4 {
    color: #D14343;
  }

  .modal > div:last-child button {
    line-height: inherit;
  }

  .dangerModal > div:last-child button:last-child {
    background-color: #D14343;
    border-color: #D14343;
  }

  .dangerModal > div:last-child button:last-child:hover {
    background-color: #A73636;
    border-color: #A73636;
  }

}
