body {
  .formMoveCard {
    width: 300px;
    padding: 24px;
    .formHeader {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 24px;
      .title {
        color: #101840;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
      }
      .closeIcon {
        display: flex;
        justify-content: flex-end;
        .icon {
          cursor: pointer;
        }
      }
    }
    .formBody {
      .board,
      .list {
        margin-bottom: 16px;
        .text {
          color: #474D66;
          font-weight: 500;
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }
  }
}