body {
  .treeContainer {
    max-height: calc(100vh - 29.75rem);
    overflow-y: auto;
  }

  .treeList {
    list-style: none;
  }

  .treeNode {
    cursor: pointer;
    margin: 0;
  }

  .treeContainer {
    list-style: none;
    padding: 0;
  }

  .treeActive {
    transform: rotate(90deg);
  }

  .menu {
    position: absolute;
    z-index: 100;
    width: 13rem;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.16);
    border-radius: 4px;

    .contextItemLabel {
      font-size: 14px;
      line-height: 20px;
      color: #474D66;
    }

    .menuItem {
      padding-left: 0;

      &:global(.trash-item) {
        color: #D14343;

        .contextItemLabel {
          color: #D14343;
        }
      }
    }
  }

  .menuList {
    padding: 12px 16px;
    color: #000;
    display: flex;
    align-items: center;
    height: 40px;
  }

  .menuList:not(:first-child) {
    cursor: pointer;
  }

  .menuList:hover:not(:first-child) {
    background-color: #f2f2f2;
    border-left: 4px solid #ccc;
  }

  .divider {
    border-bottom: 1px solid #eee;
    margin: 10px 0;
  }

  .canDrop {
    text-decoration-line: underline !important;
  }

  .folderItem {
    display: flex;
    align-items: center;
    // padding: 6px 0;
  }

  .beforeFolderItem {
    height: 6px;
    box-sizing: border-box;

    // &:hover {
    //   border-top: 1px solid #696F8C;
    // }
  }

  .afterFolderItem {
    height: 6px;
    box-sizing: border-box;
    &:global(.canDrop) {
      border-bottom: 1px solid #696F8C;
    }
  }

  .folderCaret {
    margin-right: 10px;
  }

  .folderParentCaret {
    color: #8F95B2;
  }

  .folderChildCaret {
    color: #D8DAE5;
  }

  .folderName {
    display: flex;
    align-items: center;

    .folderIcon {
      color: #8f95b2;
    }

    &:hover,
    &:global(.isActive) {
      color: #3366FF !important;

      .folderNameLabel,
      .folderIcon {
        color: #3366FF !important;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        max-width: 160px;
      }
    }
  }

  .folderNameLabel {
    font-style: normal;
    font-size: 14px;
    line-height: 24px;
    padding-left: 7px;
    color: #696F8C;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    max-width: 160px;
  }
}
