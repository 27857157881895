body {
  .actionEdit,
  .actionRemove {
    button {
      width: 100%;
      justify-content: flex-start;
      padding: 20px;
    }
  }
  .actionEdit{
    button {
      color: #8F95B2;
    }
  }
  .actionRemove {
    button {
      color: #D14343;
    }
  }
  .modal {
    background: #FFFFFF;
    .modalCard {
      border-radius: 8px;
      padding: 0;
      min-height: 100%;
      .cardLeft {
        padding: 25px;
        background: #FAFBFF;
        .btnColor {
          background: #52BD94;
          border-color: #52BD94;
          color: #fff;
        }
        .infoHeader {
          margin-top: 8px;
          display: flex;
          .members,
          .date {
            .titleMember,
            .titleDate {
              color: #696F8C;
              font-weight: 600;
              font-size: 12px;
              text-transform: uppercase;
              margin-bottom: 8px;
            }
          }
          .date {
            margin-left: 32px;
            .dateValue {
              color: #101840;
              font-size: 14px;
              font-weight: 400;
            }
          }
        }
        .title {
          font-weight: 600;
          font-size: 16px;
          color: #000000;
          padding-left: 32px;
          padding-bottom: 24px;
        }
        .inputDesription,
        .inputTask {
          width: 100%;
          cursor: pointer;
        }
        .checkBoxTask {
          margin-bottom: 10px;
          margin-top: 10px;
        }
        .checkBoxAddTask {
          margin-bottom: 10px;
          margin-top: 10px;
          span {
            color: #8F95B2;
          }
        }
        .containerAttach {
          padding-left: 32px;
          padding-bottom: 24px;
          .formFlexAttach {
            display: flex;
            align-items: center;
            padding-top: 16px;
            border-radius: 4px;
            .info {
              font-weight: normal;
              font-size: 14px;
            }
            img {
              border-radius: 4px;
            }
            .timeAct {
              color: #696F8C;
              font-size: 12px;
              padding-top: 3px;
              padding-bottom: 3px;
            }
            .actionFooter {
              color: #3366FF;
              cursor: pointer;
            }
          }
        }
        .formFlex {
          display: flex;
          align-items: center;
          color: #696F8C;
          .text {
            font-weight: 600;
            font-size: 16px;
            line-height: 24px;
            color: #474D66;
            padding-left: 16px;
          }
          .inputAct {
            width: 100%;
          }
          .titleAct {
            color: #101840;
            font-size: 14px;
            .name {
              color: #101840;
              font-weight: 500;
              font-size: 14px;
            }
          }
          .timeAct {
            color: #696F8C;
            font-size: 12px;
          }
          .formComment {
            width: 100%;
            .titleActComment {
              display: flex;
              color: #101840;
              font-weight: 500;
              font-size: 14px;
              justify-content: space-between;
              .nameComment {
                display: flex;
              }
              .moreIcon {
                display: flex;
                align-items: center;
                cursor: pointer;
                color: #8F95B2;
              }
              .timeActComment {
                color: #696F8C;
                font-size: 12px;
                display: flex;
                align-items: flex-end;
                margin-left: 7px;
                font-weight: normal;
              }
            }
            .comment {
              color: #101840;
              font-weight: normal;
              font-size: 14px;
              background: #FFFFFF;
              border: 1px solid #D8DAE5;
              box-sizing: border-box;
              border-radius: 4px;
              padding: 4px 14px 4px 14px;
              width: 100%;
              margin-top: 3px;
            }
          }
        }
        .formActivities {
          .formFlex {
            align-items: flex-start;
          }
        }
      }
      .cardRight {
        background: #E6E8F0;
        border-radius: 0px 8px 8px 0px;
        height: 100%;
        .closeIcon {
          display: flex;
          justify-content: flex-end;
          padding: 19px;
          .icon {
            cursor: pointer;
          }
        }
        .formAction {
          padding: 0 24px 24px 24px;
          .title {
            color: #696F8C;
            text-transform: uppercase;
            font-weight: 600;
            font-size: 12px;
          }
          .listAction {
            button {
              width: 100%;
              justify-content: flex-start;
              padding-left: 20px;
              color: #696F8C;
              font-size: 12px;
            }
          }
        }
      }
    }
  }
}
