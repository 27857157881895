body {
  .input {
    -webkit-box-direction: normal;
    -webkit-appearance: none;
    -webkit-font-smoothing: antialiased;
    border-radius: 4px;
    margin-right: 0;
    box-sizing: border-box;
    font-weight: 400;
    text-decoration: none;
    outline: none;
    color: #474d66;
    transition: box-shadow 80ms ease-in-out;
    height: 32px;
    font-size: 12px;
    line-height: 16px;
    background-color: white;
    letter-spacing: 0;
    border: 1px solid #D8DAE5;
    padding-right: 12px;
    padding-left: 32px;
    flex: 4 1;
    width: 100%;
    &:focus {
      z-index: 2;
      box-shadow: 0 0 0 2px #d6e0ff;
      border-color: #ADC2FF;
    }
    &[aria-invalid="true"] {
      &:not(:focus) {
        border-color: #D14343;
      }
    }
  }
}
