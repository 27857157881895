body {
  .modal {
    width: calc(34rem + 2px);
    margin-top: auto;
    margin-bottom: auto;

    div[role="combobox"] {
      margin-left: 8px;
      display: inline-block;
      width: auto !important;

      div[role="group"] {
        width: 138px;
      }

      & button {
        height: 32px;
      }
    }

    & h4:first-child {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      color: #101840;
    }
  }

  .content {
    margin-top: -16px;
    padding-bottom: 32px;
  }

  .permissionHeading {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #101840;
  }

  .sharedList {
    padding-top: 22px;
  }

  .sharedItem {
    display: flex;
    flex-direction: row;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #474D66;
    align-items: center;
  }

  .sharedItem:not(:last-child) {
    margin-bottom: 8px;
  }

  .shareTarget {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    color: #474D66;
  }

  .shareAction {
    margin-left: auto;
  }

  .separate{
    margin-top: 32px;
    margin-bottom: 24px;
  }
}
