body {
  .labelContainer {
    display: flex;
    align-items: center;
  }

  .itemLabel {
    padding-left: 8px;
  }

  .itemIcon {
    color: #696F8C;
    display: flex;
    align-items: center;
  }

  .buttonDelete,
  .buttonRestore {
    padding: auto 16px;
    border-radius: 4px;
    width: 65px;
    height: 24px;
    line-height: inherit;
  }

  .buttonRestore {
    background-color: #3366FF;
    border-color: #3366FF;
    margin-right: 0.5rem;
  }

  .buttonDelete {
    background-color: #D14343;
    border-color: #D14343;
  }
}
