body {
  .noteList {
    padding: 0;
    background: #fff;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    max-height: 100vh;

    hr {
      background: #D8DAE5;
      width: 100%;
      align-self: center;
      margin-block-end: 0;
      margin-block-start: 0;
      box-sizing: border-box;
    }
  }

  .headerContainer {
    position: sticky;
    z-index: 10;
    top: 0;
    background: #fff;
  }

  .header {
    display: flex;
    align-items: center;
    height: 40px;
    padding-left: 24px;
    padding-right: 16px;

    .headerTitle {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
      color: #101840;
      flex: 1;
    }

    button {
      margin-right: 0;
      height: 24px;
      background: #ffffff;
      border: 1px solid #d8dae5;
      box-sizing: border-box;
      border-radius: 4px;
    }
  }

  .list {
    max-height: calc(100vh - 80px);
    overflow: hidden;

    &:hover {
      overflow-y: auto;
    }
    padding-left: 24px;
    padding-right: 24px;

    &:global(.isLoading) {
      display: flex;
      justify-content: center;
      margin-top: 45vh;
    }

    ul {
      margin-top: 1rem;
    }

    li {
      margin-bottom: 12px;

      &:global(.isActive) {
        color: #3366ff !important;
      }

      &:hover {
        color: #3366ff;
        cursor: pointer;
      }
    }

    span {
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 20px;
    }

    a,
    a:hover,
    a:visited,
    a:active {
      color: inherit;
      text-decoration: none;
    }

    a:hover,
    a:active{
      color: #3366ff;
    }
  }
}
