body {
  .buttonDelete {
    padding: 8px 16px;
    border-radius: 4px;
    width: 65px;
    height: 24px;
    margin-right: 8px;
    background-color: #D14343;
    border-color: #D14343;
  }
}
