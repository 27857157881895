.panel{
  padding: 24px;
  border-radius: 4px;
  min-width: 350px;
}

.title{
  font-size: 18px;
  color: #101840;
  font-weight: 600;
}
