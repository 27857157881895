@font-face {
  font-family: 'SF Pro Text Semibold';
  src: url(../../assets/fonts/SFProText-Semibold.ttf);
}

.title{
  font-size: 20px;
  font-weight: 600 !important;
  color: #101840;
  font-family: "SF Pro Text Semibold" !important;
}

.inputPageTitle {
  padding-top: 8px;
  padding-bottom: 8px;
  input{
    border: none;
    font-family: "SF Pro Text Semibold" !important;
    font-size: 20px !important;
    font-weight: 600 !important;
    color: #101840;
  }
}

.moreButton{
  padding: 4px 0px;
  width: 24px !important;
  height: 24px !important;
  border: 1px solid #D8DAE5;
  margin-left: 16px;
  border-radius: 3px;
  cursor: pointer;
}

.moreButton:hover{
  background-color: #fafbff;
  border: 1px solid #9ca1bb;
}

.moreObjectiveButton{
  padding: 4px 0px;
  width: 20px !important;
  height: 20px !important;
  border: 1px solid #D8DAE5;
  margin-left: 16px;
  border-radius: 3px;
  cursor: pointer;
  background-color: white;
}

.moreObjectiveButton:hover{
  background-color: #fafbff;
  border: 1px solid #9ca1bb;
}

.newObjectiveBtn {
  line-height: inherit;
  height: 32px;
  border-radius: 4px;
}

.objectivePanel{
  background: #F4F6FA;
  border-radius: 8px;
  border: 1px solid #E6E8F0;
  padding: 32px;
  cursor: pointer;
}

.objectiveTitle,.inputObjectiveTitle{
  color: #101840 !important;
  font-size: 18px !important;
  font-weight: 600 !important;
  font-family: "SF Pro Text Semibold" !important;
  border: none;
}

.dropResultOver{
  height: 40px;
  background-color: #EBF0FF;
  width: 100%;
  border-radius: 4px;
  margin-top: 4px;
  margin-bottom: 4px;
}

.dropObjectiveOver{
  height: 40px;
  background-color: #EBF0FF;
  width: 100%;
  border-radius: 4px;
  margin-top: 24px;
  margin-bottom: 24px;
  //margin-bottom: 4px;
}
