body {
  .infoModal {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    width: 100%;
  
    tr {
      margin-bottom: 0.2rem;
      display: flex;
      justify-content: space-between;

      td:last-child {
        font-weight: bold;
        color: #474D66;
      }
    }
  }
  
  .okBtn {
    margin-left: auto;
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem; 
  }
}
