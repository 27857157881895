.projectName{
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 160px;
}

.selected{
  background-color: #EBF0FF;
}

.canDropProject{
  border-bottom: 2px solid black;
}

.projectItem{
  border-radius: 4px;
  padding-left: 16px !important;
}

.projectItem:hover{
  span{
    color: #3366FF;
  }
}

.dropOver{
  border: 2px solid #D6E0FE !important;
}

.previewDropPosition{
  width: 224px;
  height: 28px;
  border-radius: 4px;
  background-color: #EBF0FF;
}
