body {
    .formFilterCard {
        display: grid;
        padding: 24px;
        width: 352px;
        .formHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;
            .title {
                color: #101840;
                font-weight: 600;
                font-size: 18px;
                line-height: 24px;
            }
            .closeIcon {
                display: flex;
                justify-content: flex-end;
                .icon {
                  cursor: pointer;
                }
            }
        }
        .members {
            margin-bottom: 16px;
            .title {
                color: #474D66;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 16px;
            }
            .listMember {
                .item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    cursor: pointer;
                    .itemInfo {
                        display: flex;
                        align-items: center;
                        .titleItem {
                            color: #101840;
                            font-weight: normal;
                            font-size: 14px;
                        }
                    }
                    .iconCheck {
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
        .labels {
            .title {
                color: #474D66;
                font-weight: 500;
                font-size: 14px;
                margin-bottom: 16px;
            }
            .listLabel {
                .item {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 8px;
                    border-radius: 8px;
                    padding: 8px 19px 8px 19px;
                    color: #ffff;
                    align-items: center;
                    cursor: pointer;
                    .titleItem {
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                    }
                    .iconCheck {
                        display: flex;
                    }
                }
            }
        }
    }
}