body {
    .formLabel {
        width: 300px;
        padding: 24px;
        .formHeader {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding-bottom: 24px;
            .title {
                color: #101840;
                font-weight: 600;
                font-size: 16px;
                line-height: 24px;
            }
            .closeIcon {
                display: flex;
                justify-content: flex-end;
                .icon {
                  cursor: pointer;
                }
            }
        }
        .listLabel {
            .itemLabel {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 8px;
                .item {
                    display: flex;
                    justify-content: space-between;
                    border-radius: 8px;
                    padding: 8px 19px 8px 19px;
                    color: #ffff;
                    align-items: center;
                    cursor: pointer;
                    width: 100%;
                    margin-right: 15px;
                    .titleItem {
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 16px;
                    }
                    .iconCheck {
                        display: flex;
                    }
                }
            }
            .iconEdit {
                color: #8F95B2;
                cursor: pointer;
            }
        }
        .formBodyAdd {
            .formTitleAdd {
                margin-bottom: 24px;
                .title {
                    margin-bottom: 8px;
                    color: #474D66;
                    font-weight: 500;
                    font-size: 14px;
                }
                .inputTitle {
                    width: 100%;
                }
            }
            .formColorAdd {
                .title {
                    margin-bottom: 8px;
                    color: #474D66;
                    font-weight: 500;
                    font-size: 14px;
                }
                .formChangeBg {
                    display: grid;
                    grid-template-columns: auto auto auto auto auto auto auto;
                    grid-gap: 8px;
                }
            }
            .palette {
                width: 28px;
                height: 28px;
                border-radius: 4px;
                color: #fff;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                display: flex;
            }
        }
        .formFooter {
            display: flex;
            justify-content: flex-end;
            margin-top: 20px;
            .buttonAddLabel {
                background: #52BD94;
                border-color: #52BD94;
                color: #fff;
            }
        }
    }
}