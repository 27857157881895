body {
  .input {
    font-size: 12px;
    color: #000000;

    border: none !important;
    box-shadow: none !important;
    font-size: 12px;
  
    ::placeholder {
      color: #000000;
      opacity: 1;
    }

    strong {
      height: 1.5rem;
      padding: 4px 8px;
      margin: 0;
      margin-right: 8px;
    }

    div[role="combobox"] {
      button {
        display: none;
      }
    }
  }
  
}
