body {
  .closeContainer {
    -webkit-box-direction: normal;
    -webkit-font-smoothing: antialiased;
    -webkit-appearance: none;
    border-radius: 4px;
    box-sizing: border-box;
    position: absolute;
    display: inline-flex;
    align-items: center;
    -webkit-box-align: center;
    flex-wrap: nowrap;
    justify-content: center;
    -webkit-box-pack: center;
    text-decoration: none;
    vertical-align: middle;
    border: 1px solid transparent;
    outline: none;
    user-select: none;
    cursor: pointer;
    white-space: nowrap;
    transition: box-shadow 80ms ease-in-out;
    height: 32px;
    min-width: 32px;
    font-size: 12px;
    font-weight: 400;
    color: #8F95B2;
    line-height: 16px;
    padding-left: 0px;
    padding-right: 0px;
    flex: none;
    letter-spacing: 0;
    width: 32px;
    background-color: transparent;
    &:not([disabled]) {
      &:hover {
        background-color: #F4F5F9;
        border: none;
      }
      &:active {
        background-color: #edeff5;
      }
      &:focus {
        box-shadow: none;
        //box-shadow: 0 0 0 2px #d6e0ff;
      }
      &:not([disabled])[aria-expanded="true"] {
        background-color: #edeff5;
      }
      &:not([disabled])[data-active] {
        background-color: #edeff5;
      }
    }
    &[data-simulate-notdisabledhover] {
      background-color: #F4F5F9;
    }
    &[data-simulate-notdisabledfocus] {
      box-shadow: 0 0 0 2px #d6e0ff;
    }
  }
  .container {
    background-color: #FFFFFF;
    .menubarContainer {
      top: 0;
      left: 0;
      right: 0;
      height: 40px;
    }

    .zwfToolbar {
      top: 40px;
      left: 0;
      right: 0;
      height: 48px;
      z-index: 1;
    }

    .sidebarContainer {
      background-color: #FFFFFF;
      position: absolute;
      box-sizing: border-box;
      top: 88px;
      border: 1px solid #d8dae5;
      left: 0;
      width: 255px;
      bottom: 0;
      overflow: hidden;
    }

    .formatContainer {
      right: 0;
      z-index: 1;
      top: 88px;
      width: 244px;
      bottom: 0;
      position: absolute;
      overflow: hidden;
      background-color: #FFFFFF;
      box-shadow: 0 4px 8px rgba(16, 24, 64, 0.08);
    }

    .commentBarContainer {
      right: 0;
      z-index: 1;
      top: 88px;
      width: 0;
      bottom: 0;
      position: absolute;
      overflow: hidden;
      background-color: #FFFFFF;
      box-shadow: 0 4px 8px rgba(16, 24, 64, 0.08);
    }

    .commentBarMindMap {
      overflow: initial;
      height: 100vh;
      padding-bottom: 4rem;
      display: none;
    }

    .commentBarView {
      top: 47px !important;
    }

    .commentBarVisible {
      width: 288px;
      display: block;
    }

  }
  .mindMapBar {
    position: absolute;
    width: 100%;
    z-index: 19;
  }
}
