body{
  .mainPage{
    .templates{
      margin-top: 16px;
      border-top: 1px solid #D8DAE5;
      grid-template-columns: 360px 360px 360px;
      @media only screen and (max-width: 1400px) {
        grid-template-columns: 360px 360px;
      }
      display: grid;

      .template{
        padding-top: 16px;
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
      }
    }
  }
  .titlePage{
    font-weight: 600;
    font-size: 20px;
  }
  .templateCard{
    padding: 16px;
    background: white;
    border-radius: 4px;
  }
}
