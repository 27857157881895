@font-face {
  font-family: 'SF Pro Text Semibold';
  src: url(../../assets/fonts/SFProText-Semibold.ttf);
}

.taskSelected{
  background-color: #EBF0FF;
  border-radius: 4px;
}

.taskDropOver{
  height: 32px;
  width: 100%;
  background-color: #EBF0FF;
  border-radius: 4px;
}

.taskExpanded{
  border: 1px solid #D8DAE5;
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
}

.taskItem{
  margin-top: 8px;
  margin-bottom: 8px;
}

.descriptionArea{
  color: #101840;
  font-size: 14px;
  padding: 0px;
  resize: none;
  border: none !important;
  width: 100%;
  align-self: center;
  min-height: 18px;
  font-family: "SF Pro Text" !important;
  line-height: 20px;
}

.descriptionArea:focus{
  outline: none;
}

.inputText{
  ::placeholder{
    color: #C1C4D6;
  }
}

.inputTask{
  box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
  background-color: white;
}

.noSelectAble{
  -webkit-user-select: none;  /* Chrome all / Safari all */
  -moz-user-select: none;     /* Firefox all */
  -ms-user-select: none;      /* IE 10+ */
  user-select: none;          /* Likely future */
}

.headingSelected{
  background-color: #EBF0FF;
}

.headingPanel{
  height: 32px;
  border-radius: 4px;
  align-items: center;
  align-content: center;
  display: flex;
  padding-left: 16px;
  padding-right: 16px;
}

.headingTitleInput{
  font-size: 14px;
  background-color: #EBF0FF;
  color: #101840;
  border: none;
  padding: 0px;
  resize: none;
  border: none;
  width: 100%;
  align-self: center;
  min-height: 18px;
  font-family: "SF Pro Text Semibold" !important;
  line-height: 16px;
  font-weight: bold;
}

.headingTitleInput{
  ::placeholder{
    color: #C1C4D6;
  }
}

.headingTitleInput:focus{
  outline: none;
}

.headingTitle{
  font-size: 14px;
  font-family: "SF Pro Text Semibold" !important;
  line-height: 16px;
  font-weight: 600;
  color: #101840 !important;
  align-self: center;
}

.deleteBtn:hover{
  background-color: #fafbff;
  border: 1px solid #8f95b2;
}

.sliderWrap{
  width: 112px;
  height: 24px;
  padding-left: 16px;
  display: grid;
}

.slider{
  margin: 0px !important;
  align-self: center;
}

.progress{
  width: 67px;
  height: 24px;
  border-radius: 4px;
  border: 1px solid #D8DAE5;
  display: grid;
}
