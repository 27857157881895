body {
    .formChangeBg {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
        grid-gap: 8px;
        padding-bottom: 25px;
        .buttonChangeBg {
            color: #fff;
            path {
                fill: #fff;
            }
            &:hover {
                background: 0%;
            }
        }
    }
}