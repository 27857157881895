body{
  .mainPage{
    height: 100%;
    padding: 20px;
    .pageHeaderBoard {
      display: flex;
      height: 40px;
    }
    .formNoData {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      text-align: center;
      color: #FFFFFF;
      .title {
        font-weight: 600;
        font-size: 24px;
      }
      .content {
        font-size: 14px;
        margin: 14px 24px 24px 24px;
      }
      .createNewBoardBtn {
        background: #52BD94;
        border-color: #52BD94;
        border-radius: 4px;
        color: #fff;
        box-shadow: unset;
        padding: 8px 16px;
        height: 32px;
        font-size: 14px;
      }
    }
    .parentContainer {
      height: 100%;
      overflow-x: hidden;
      overflow-y: auto;
      margin-top: 12px;
      .Container {
        display: inline-flex;
        .btnColor {
          background: #52BD94;
          border-color: #52BD94;
          color: #fff;
        }
        .containerAnother {
          .titleAnother {
            background: #8f95b266;
            height: 40px;
            width: 272px;
            border-radius: 4px;
            margin: 8px;
            line-height: 40px;
            padding-left: 16px;
            cursor: pointer;
            .contentAnother {
              color: #fff;
            }
          }
          .formAnother {
            margin: 8px;
            border-radius: 4px;
            padding: 8px;
            background-color: #EDEFF5;
          }
        }
      }
      .containerColumn {
        margin: 8px;
        display: flex;
        flex-direction: column;
        background-color: #EDEFF5;
        border-radius: 4px;
        height: 100%;
        .headerColumn {
          display: flex;
          align-items: center;
          justify-content: center;
          border-top-left-radius: 4px;
          border-top-right-radius: 4px;
          transition: background-color 0.2s ease;
          .title {
            padding: 8px 8px 0px 15px;
            flex-grow: 1;
            user-select: none;
            position: relative;
            font-weight: 600;
            font-size: 14px;
            color: #000000;
            transition: background-color ease 0.2s;
          }
          h4 {
            margin-bottom: 0;
          }
        }
        .bottomColumn { 
          margin: 0px 8px 8px 8px;
          border-radius: 4px;
          cursor: pointer;
          .bottomAddCard {
            border-radius: 4px;
            &:hover {
              background: #D8DAE5;          
            }
          }
          .inputAddCard {
            width: 256px;
          }
          .addCardBtn {
            color: #696F8C;
            font-size: 14px;
            padding: 10px;
            &:hover {
              background-color: unset;
            }
            &:focus {
              box-shadow: unset;
            }
          }
        }
      }
      .containerItem {
        box-shadow: 0px 4px 8px rgba(16, 24, 64, 0.08);
        border-radius: 4px;
        background: #FFFFFF;
        padding: 8px 12px 8px 12px;
        min-height: 40px;
        margin: 10px 8px 10px 8px;
        user-select: none;
        &:hover {
          background: #F3F6FF;
        }
        .contentItem {
            display: flex;
            flex-direction: column;
            flex-grow: 1;
            flex-basis: 100%;
            .listAvatar {
              display: flex;
              justify-content: flex-end;
              .avatar {
                margin-left: 3px;
              }
            }
        }
      }
      .wrapperColumn {
        display: flex;
        flex-direction: column;
        padding-bottom: 0;
        transition: background-color 0.2s ease, opacity 0.1s ease;
        user-select: none;
        width: 272px;
        overflow: hidden auto;
        max-height: calc(100vh - 180px);
        .dropZone {
          min-height: 10px;
        }
        .scrollContainerStyle {
          overflow-x: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}
