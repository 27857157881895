body {
  .modal {
    margin-top: auto;
    margin-bottom: auto;
  }

  .modalContent {
    font-size: 14px;
    line-height: 20px;
    color: #474D66;
    min-height: 0;
  }

  .modal > div:last-child button {
    line-height: inherit;
  }

  .modalDanger {
    h4 {
      color: #D14343;
    }
    div:last-child button:last-child {
      background-color: #D14343;
      border-color: #D14343;
    }
    div:last-child button:last-child:hover {
      background-color: #A73636;
      border-color: #A73636;
    }
  }

  .modalEditLink {
    > div:last-child {
      width: 100%;
      > div:first-child {
        width: 100%;
      }
    }
  }
}
