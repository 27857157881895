body {
  .paletteContainer {
    display: flex;
    align-items: center;
    margin-top: 1rem;
    .palette:last-child {
      margin-right: 0;
    }
    .palette {
      margin-right: 1rem;
      width: 24px;
      height: 24px;
      border-radius: 24px;
      cursor: pointer;
      &.active,
      &:hover {
        border: 2px solid #9DB5FF;
      }
    }
    .paletteActive {
      border: 2px solid #9DB5FF !important;
    }
    .paletteBorder {
      border: 1px solid #474D66;
    }
  }
}
