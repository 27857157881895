body {
  .formSideBarSound {
    margin-top: 40px;
    .folderContainer {
      display: flex;
      justify-content: space-between;
      padding: 0px 8px 0px 12px;
      cursor: pointer;
      .sidebarLabel {
        font-weight: 500;
        color: #101840;
        font-size: 14px;
      }
      &:hover{
        .sidebarLabel {
          color: #3366FF;
        }
      }
      .textFormat {
        display: flex;
        align-items: center;
      }
      .text {
        margin-right: 0;
        font-size: 11px;
        color: #474D66;
      }
      &.active {
        background-color: #EBF0FF;
        border-radius: 4px;
      }
    }
  
    .playForm,
    .volumeForm {
      padding: 0px 8px 0px 12px;
    }
    .titlePage {
      padding-top: 30px;
      padding-bottom: 15px;
      color: #101840;
      font-weight: 600;
      font-size: 12px;
      line-height: 16px;
      text-transform: uppercase;
    }
  
    .slider-container {
      .inputVolume {
        background-color: red;
      }
    }

    .valueTimer {
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #101840;
    }
  
    .inputVolume {
      display: block;
      -webkit-appearance: none;
      height: 4px;
      margin-bottom: 6px;
      border-radius: 10px;
      background: #C1C4D6;
      outline: none;
      opacity: 0.7;
      -webkit-transition: .2s;
      transition: opacity .2s;
    }
    
    .inputVolume:hover {
      opacity: 1;
    }
    
    .inputVolume::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      width: 12px;
      height: 12px;
      background: #474D66;
      border-radius: 50%;
      cursor: pointer;
    }
    
    .inputVolume::-moz-range-thumb {
      width: 12px;
      height: 12px;
      background: #474D66;
      border-radius: 50%;
      cursor: pointer;
    }
  
    .play {
      align-items: center;
      .playBtn {
        margin-right: 10px;
      }
    }
  
    .sidebarLabel {
      margin-left: 18px;
      font-size: 14px;
      line-height: 24px;
      color: #696F8C;
    }
  
    .colorVolume {
      color: #3366FF;
    }
  
    .colorStar {
      color: #FFB020;
    }
  
    .colorMoon {
      color: #897AE3;
    }
  
    .colorTree {
      color: #52BD94;
    }
  
    .colorTint {
      color: #678AF7;
    }
  }
}
